import React from 'react'
import TemplateView from '../components/TemplateView/TemplateView'

const TemplateViewPage = () => {
  return (
    <div>
        <TemplateView />
    </div>
  )
}

export default TemplateViewPage