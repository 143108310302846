import React from 'react';
import './About.css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t, i18ns } = useTranslation();
    return (
        <div className="about-us">
            <Typography
              variant="h2"
              component="h2"
              className="thas-heading text-heading h2-heading-font"
            >
              {t("About us")}
            </Typography>
            <p>
            {t("At THAS Multitech, we have a burning passion for digital design and innovation. Our team, comprised of dedicated professional designers and technicians, works passionately on every project. Our vision is to be the bridge between concept and reality, between dream and digital reality.")}
            </p>
        </div>
    );
}

export default AboutUs;

