import AppRoutes from './App.routes';
import '../../App.css'
import React, { useEffect, useState } from 'react';
import i18n from '../../assests/i18n/i18n';
function App() {
  const [isScrolled, setIsScrolled] = useState(false)
  const hash = window.location.hash;
  let contactSection;
  useEffect(() => {
    if (hash === '#contact') {
      contactSection = document.getElementById('contact');
    }
    else if (hash === '#services'){
      contactSection = document.getElementById('services');
    }
    else if (hash === '#about'){
      contactSection = document.getElementById('about');
    }

    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: 'smooth',
      });

    }
  }, []);
  
  return (
    <AppRoutes />
  );
}

export default React.memo(App);
