import React, { useState, useEffect } from 'react'
import './Template.css'
import { Typography } from '@mui/material'
import demoVideo from '../../assests/Video/template-demo.mp4'
import TemplateScreenshotsData from './TemplateScreenshotsData'
import { get, ref } from "@firebase/database";
import { db } from "../../firebaseConfig";
import { useParams } from 'react-router-dom'
import Skeleton from "@mui/material/Skeleton";

const TemplateView = () => {
    const { id } = useParams()
    const [templateData, settemplateData] = useState([]);
    const webDevRef = ref(db, `website-templates/${id}`);
    const [isLoading, setIsLaoding] = useState(false);

    useEffect(() => {
        setIsLaoding(true);
        get(webDevRef)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const result = snapshot.val();
                    settemplateData(result);
                    setIsLaoding(false);
                } else {
                    setIsLaoding(false);
                    console.log("Template do not exist.");
                }
            })
            .catch((error) => {
                setIsLaoding(false);
                console.error("Error fetching Treatment Texts:", error);
            });
    }, []);
    console.log(templateData)

    return (
        <>
            {isLoading ? <Skeleton style={{ height: "80vh" }}></Skeleton> :
                <div className='template-container'>
                    <div className="template-video">
                        <Typography variant='h1'  sx={{fontSize: "3rem"}} component={"h1"} className='mb-3 thas-heading text-center text-dark'>{templateData['title']}</Typography>
                        {templateData['video'] &&
                            <video controls autoPlay={true}>
                                <source src={templateData['video']} type="video/mp4" />
                            </video>}
                    </div>
                    <div className="template-screnshots">
                        <Typography variant='h5' component={"h5"} className='text-center text-heading'>Screenshots</Typography>
                        <div className="d-flex template-images">
                            {templateData['images'] && templateData['images'].map((data, key) => {
                                return (
                                    <img src={data} key={key} alt="..." />
                                )
                            })}
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default TemplateView