import React from 'react';
import HeaderImg from '../../assests/Group 1.png';
import './hero.css'
import { useTranslation } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();

    return (
        <div className="hero-container">
                <div className="intro-text">
                    <h1>{t('From Concept to Creation')}</h1>
                    <p className='text-center'>{t('We design, develop, and deliver your imagination.')}</p>
                </div>
                <div className="intro-img">
                    <img src={HeaderImg} width="auto" height="auto" alt="Thas Multitech AB" />
                </div>
        </div>
    );
}

export default Hero;
