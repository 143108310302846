import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from '../../pages/Home';
import Wrapper from '../wrapper/Wrapper';
import TemplateDetails from '../templatesList/TemplateDetails';
import { dummyTemplate } from '../../shared/mocks/templateDetails.mock';
import ServicesPage from '../../pages/Services';
import ContactUsPage from '../../pages/Contact';
import ProjectPage from '../../pages/Projects';
import AboutPage from '../../pages/About';
import TemplateViewPage from '../../pages/TemplateView';
import WorkWithUsPage from '../../pages/WorkWithUs';
import AppTemplateView from '../TemplateView/AppTemplateView';
import EmbeddedProgramming from '../../pages/EmbeddedProgramming';
import CustomCircuitDesigns from '../../pages/CustomCircuitDesigns';
import PCBDesign from '../../pages/PCBDesign';
import Advertisement from '../../pages/Advertisement';
import Animation from '../../pages/Animation';
import LogoDesign from '../../pages/LogoDesign';
import GraphicDesign from '../../pages/GraphicDesign';
import CadDesign from '../../pages/CadDesign';
import AppDevelopmentPage from '../../pages/AppDevelopmentPage';
import WebDevelopmentPage from '../../pages/WebDevelopmentPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Wrapper />}>
        <Route index element={<Home />} />
        {/* <Route path="/templates/:id" element={<TemplateDetails template={dummyTemplate} />} /> */}
        <Route path="/work-with-us" element={<WorkWithUsPage />} />
        <Route path="/web-template/:id" element={<TemplateViewPage />} />
        <Route path="/app-template/:id" element={<AppTemplateView />} />
        <Route path="/embedded-programming" element={<EmbeddedProgramming />} />
        <Route path="/circuit-design" element={<CustomCircuitDesigns />} />
        <Route path="/pcb-design" element={<PCBDesign />} />
        <Route path="/app-development" element={<AppDevelopmentPage />} />
        <Route path="/web-development" element={<WebDevelopmentPage />} />
        <Route path="/advertisement" element={<Advertisement />} />
        <Route path="/cad-design" element={<CadDesign />} />
        <Route path="/graphic-design" element={<GraphicDesign />} />
        <Route path="/logo-design" element={<LogoDesign />} />
        <Route path="/animated-video" element={<Animation />} />
      </Route>
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>

  )
}

export default AppRoutes