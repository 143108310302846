import React from 'react';
import './WorkWithUs.css';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function WorkWithUs() {
  const { t, i18ns } = useTranslation();
  return (
    <section className="work-with-section">
      <div
        style={{
          background: "#fff",
          backgroundSize: "100% 50%",
          opacity: "1",
        }}
      >
        <Box className="work-with-container">
          <div className="">
            <Typography
              variant="h1"
              component="h1"
              className="thas-heading text-heading wws-heading"
            >
              {t("Work with us")}
            </Typography>
            <p className="thas-subheading text-start text-gray">
              {t("At THAS Multitech, our mission extends beyond just providing services; it's about building lasting partnerships. If you have the ability to connect us with potential opportunities in any of our fields of expertise, we want to hear from you. Whether you're an individual with a lead, a professional in the industry, or someone with a network that aligns with our services, your collaboration can be invaluable. If you believe there's a job or project we can undertake, don't hesitate to reach out. Let's grow and succeed together.")}
            </p>
          </div>

          <div className="work-with-us-contact">
            <Typography
              variant="h2"
              component="h2"
              className="thas-heading text-start git-heading text-heading"
            >
              {t("Get in Touch")}
            </Typography>
            <p>{t("We're eager to connect with you. Reach out to us through any of the following:")}</p>

            <p><strong>{t("Email")}:</strong> info@thas-multitech.com</p>
            <p><strong>{t("Phone")}:</strong> +46 760211685</p>
            <p><strong>{t("Message")}:</strong> {t("Use our website's contact form.")}&nbsp;
              <a href='/#contact' className="messageus-link">
                <strong>{t('Message Us')}</strong>
              </a>
            </p>
          </div>


        </Box>
      </div>
    </section>
  );
}

export default WorkWithUs;
