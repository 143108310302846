import React from 'react';
import img from '../../assests/pcb.png';
import img1 from "../../assests/circuit.png";
import img2 from "../../assests/website.png";
import img3 from "../../assests/embeded.png"
import img4 from "../../assests/app.png"
import img5 from '../../assests/ad.png'
import img6 from '../../assests/cad.png'
import img7 from '../../assests/curve (1).png'
import img8 from '../../assests/logo-design.png'
import img9 from '../../assests/animate.png'
import './Services.css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'; // Import Helmet

const Services = () => {
    const { t, i18ns } = useTranslation();

    const handleServiceSelected = (service) => {
        window.location.href = "/" + service;
    }
    return (
        <section className="services-section">
            <Helmet>
                {/* <title>Our Services - Thas Multitech</title> */}
                <meta name="description" content="Explore our wide range of technology services including custom circuit designs, PCB design, app development, web development and more." />
            </Helmet>
            <div className="services-container">
                <div className="services-header">
                    <Typography
                        variant="h2"
                        component="h2"
                        className="thas-heading text-heading h2-heading-font"
                    >
                        {t("Our Services")}
                    </Typography>
                    <p className="thas-subheading text-gray">
                        {t("At Thas Multitech, we offer a comprehensive suite of technology solutions tailored to meet your diverse needs. Our expert team specializes in the following services.")}
                    </p>
                </div>
                <div className="services-cards">
                    <div className="service-card" onClick={() => handleServiceSelected("circuit-design")}>
                        <img width="auto" height="auto" src={img} alt="Custom Circuit Designs" />
                        <p className="service-text">{t("Custom Circuit Designs")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("pcb-design")} >
                        <img width="auto" height="auto" src={img1} alt="PCB Design" />
                        <p className="service-text">{t("PCB Design")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("embedded-programming")} >
                        <img width="auto" height="auto" src={img2} alt="Embedded Programming" />
                        <p className="service-text">{t("Embedded Programming")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("app-development")}>
                        <img width="auto" height="auto" src={img4} alt="App Development" />
                        <p className="service-text">{t("App Development")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("web-development")} >
                        <img width="auto" height="auto" src={img3} alt="Web Development" />
                        <p className="service-text">{t("Web Development")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("advertisement")}>
                        <img width="auto" height="auto" src={img5} alt="Social Media Advertisement"  />
                        <p className="service-text">{t("Social Media Advertisement")}</p>

                    </div>
                    <div className="service-card"  onClick={() => handleServiceSelected("cad-design")} >
                        <img width="auto" height="auto" src={img6} alt="CAD Design"/>
                        <p className="service-text">{t("CAD Design")}</p>

                    </div>
                    <div className="service-card" onClick={() => handleServiceSelected("graphic-design")}>
                        <img width="auto" height="auto" src={img7} alt="Graphic Design"  />
                        <p className="service-text">{t("Graphic Design")}</p>

                    </div>
                    <div className="service-card"  onClick={() => handleServiceSelected("logo-design")}>
                        <img width="auto" height="auto" src={img8} alt="Logo Design" />
                        <p className="service-text">{t("Logo Design")}</p>

                    </div>
                    <div className="service-card"  onClick={() => handleServiceSelected("animated-video")}>
                        <img width="auto" height="auto" src={img9} alt="Animated Video Production" />
                        <p className="service-text">{t("Animated Video Production")}</p>

                    </div>

                </div>
            </div>
        </section>
    );
}

export default Services;
