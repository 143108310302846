import React from 'react'
import img6 from "../assests/Group 10371.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const GraphicDesign = () => {

    return (
        <>
            <ServicesComponent
                img={img6}
                thead="Graphic Design"
                desc="At THAS Multitech, we approach graphic design with a thoughtful touch. From brochures to banners, we aim to communicate your message clearly and pleasantly through our designs."
                idName="graphic-design"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default GraphicDesign