import React from 'react'
import ContactUs from '../components/contactUs/ContactUs';
import AppDevelopment from '../components/work/AppDevelopment';

const AppDevelopmentPage = () => {

    return (
        <>
        <AppDevelopment />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default AppDevelopmentPage