import React from 'react'
import img7 from "../assests/Group 10372.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const LogoDesign = () => {

    return (
        <>
            <ServicesComponent
                img={img7}
                thead="Logo Design"
                desc="A logo is the face of your brand. At THAS Multitech, we craft distinctive and memorable logos that resonate with your brand's identity, ensuring that you leave a lasting impression on your audience."
                idName="logo-design"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default LogoDesign