import React from 'react'
import img2 from "../assests/Group 104.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const PCBDesign = () => {

    return (
        <>
            <ServicesComponent
                img={img2}
                thead="PCB Design"
                desc="At THAS Multitech, we are dedicated to bringing your electronic visions to life. Whether starting from a schematic we've crafted or utilizing one you provide, our team is adept at transforming these blueprints into efficient PCB designs. With a staunch commitment to industry standards, we ensure each PCB design is not only secure but also robust against EMI and EMC interferences. Trust in our meticulous approach to deliver PCB solutions that are both reliable and optimized for performance."
                idName="pcb-design"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default PCBDesign