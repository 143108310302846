import React, { useState, useEffect } from "react";
import { get, ref } from "@firebase/database";
import { db } from "../../firebaseConfig";
import "./Work.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { Helmet } from "react-helmet";

const WebDevelopment = ({ showOnNew }) => {
  const { t, i18ns } = useTranslation();
  const [webDevData, setwebDevData] = useState([]);
  const [AllWebDevData, setAllWebDevData] = useState([]);
  const [isLoading, setIsLaoding] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isShowViewMore, setisShowViewMore] = useState(true);
  const total_d = 8;

  const webDevRef = ref(db, "website-templates");
  const nav = useNavigate();
  useEffect(() => {
    setIsLaoding(true);
    get(webDevRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const result = [];

          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            const data = childSnapshot.val();
            const resultItem = { key, data };
            result.push(resultItem);
          });
          setwebDevData(result.slice(0, total_d));
          setAllWebDevData(result);
          setIsLaoding(false);
        } else {
          setIsLaoding(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Treatment Texts:", error);
      });
  }, [isDataLoaded]);

  const handleViewMore = () => {
    const resp = AllWebDevData;
    setwebDevData(resp);
    setisShowViewMore(false);
  };

  const handleViewLess = () => {
    const resp = AllWebDevData.slice(0, total_d);
    setwebDevData(resp);
    setisShowViewMore(true);
  };

  return (
    <section className={`${showOnNew ? "work-section" : "work-section-gray"}`}>
      <Helmet>
        {/* <title>Web Development - Our Featured Work</title> */}
        <meta
          name="description"
          content="Explore our curated collection of web development projects, showcasing innovative design and functionality."
        />
        {/* You can add more meta tags or other elements here */}
      </Helmet>

        <Box className="work-container">
          {!showOnNew && (
            <div className="work-header">
              <Typography
                variant="h2"
                component="h2"
                className="thas-heading text-heading h2-heading-font"
              >
                {t("Our Featured Work")}
              </Typography>
              <p className="thas-subheading text-gray">
                {t(
                  "Dive into a curated collection of digital templates, where design meets possibility, offering you a world of inspiration at your fingertips."
                )}
              </p>
            </div>
          )}

          <div style={{ padding: "2rem 0" }}>
            <div className="d-flex justify-content-center mb-3">
              <Typography
                variant="h4"
                component={"h4"}
                className="m-0 mb-3 text-heading"
              >
                {t("Web Development")}
              </Typography>
            </div>
            {isLoading ? (
              <Skeleton style={{ height: "50vh" }}></Skeleton>
            ) : (
              <>
                <div className="template-thumbnails-grid text-center">
                  {webDevData.map((data, index) => (
                    <div key={index}>
                      <img
                        src={data.data["cover"]}
                        className="img-thumbnail template-thumbnail-items mb-3 mt-4"
                        alt="Web Development Thumbnail"
                        width="100"
                        height="100"
                        onClick={() => {
                          nav(`/web-template/${data.key}`);
                        }}
                      />
                      <span className="thas-subheading template-name text-gray">
                        {data.data["title"]}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-center work-view-more-section">
                  {isShowViewMore ? (
                    <button
                      className="btn btn-secondary"
                      style={{ width: "15%" }}
                      onClick={() => handleViewMore()}
                    >
                      View More
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      style={{ width: "15%" }}
                      onClick={() => handleViewLess()}
                    >
                      View Less
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Box>
    </section>
  );
};

export default WebDevelopment;
