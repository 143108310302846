import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './ServicesComponent.css'

const ServicesComponent = ({img, thead, desc, idName, height, top}) => {
    const { t } = useTranslation()
  return (
    <section className="service-components-section" id="embedded-programming">
    <div className="service-components-container">
        <div>
            <div className="service-components-box">
                <div className="service-components-text">
                    <Typography
                        variant="h4"
                        component="h4"
                        className="service-components-heading text-light mb-3"
                    >
                        {t(thead)}
                    </Typography>
                    <p className="service-components-paragraph text-light">{t(desc)}</p>
                </div>
                <div className="service-components-image-container">
                    <img
                        src={img}
                        alt={`${thead}`.toLowerCase()}
                        width="100"
                        height="100"
                        className="service-components-image"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default ServicesComponent