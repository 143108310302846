import React from 'react'
import WorkWithUs from '../components/WorkWithUs/WorkWithUs'

const WorkWithUsPage = () => {
    return (
            <div id='work-with-us'>
                <WorkWithUs />
            </div>
    )
}

export default WorkWithUsPage