import React from 'react'
import img1 from "../assests/Group 103.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const CustomCircuitDesigns = () => {

    return (
        <>
            <ServicesComponent
                            img={img1}
                            thead="Custom Circuit Designs"
                            desc="At THAS Multitech, we understand the unique technological needs of our clients. Whether you need a completely new circuit designed specifically for your requirements or are looking to improve and troubleshoot an existing device, our team is here to help. Beyond design and debugging, we also provide comprehensive measurement and simulation services. Count on us to deliver functionality, reliability, and precision throughout the entire process."
                            idName="circuit-design"
                        />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default CustomCircuitDesigns