import React from 'react';
import './About1.css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function About1() {
    const { t } = useTranslation();
    
    return (
        <div className="about1-us">
            <Typography
              variant="h4"
              component="h4"
              className="thas-heading text-heading"
            >
              {t("Why Choose Us")}
            </Typography>
            <div className="section">
                <h2>{t("Expertise")}:</h2>
                <p>{t("Our team has extensive experience in digital design, and every template we present is a result of meticulous research and creativity.")}</p>
            </div>
            
            <div className="section">
                <h2>{t("Adaptability")}:</h2>
                <p>{t("While our templates serve as inspiration, we always tailor our solutions to meet the unique needs and visions of our clients.")}</p>
            </div>
            
            <div className="section">
                <h2>{t("Quality Focus")}:</h2>
                <p>{t("We always prioritize quality, ensuring that every template is of the highest standard, both aesthetically and functionally.")}</p>
            </div>

            <div className="section">
                <h2>{t("Innovation-Driven")}:</h2>
                <p>{t("In an ever-changing digital landscape, we consistently stay one step ahead through continuous research and development.")}</p>
            </div>
            
            <div className="section">
                <h2>{t("Partnership")}:</h2>
                <p>{t("As we always say, 'every project is a partnership'. We value our clients' input and always ensure close collaboration to achieve the best possible results.")}</p>
            </div>          
        </div>
    );
}

export default About1;
