import React from "react";
// import vv from "../../shared/assets/images/vv.png";
import "./Footer.css";
import { Box, Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Logo from "../../assests/logoWhite.png"
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18ns } = useTranslation();
    return (
        <section className="footer-section">


            <Box
                className={`footer-container`}
            >
                <div className="footer-details">
                    <div className="footer-items-logo">
                        <img
                            src={Logo}
                            width="auto"
                        height="auto"
                            style={{ width: "50%" }}
                            className="mb-3"
                            alt="Thas Multitech AB"
                        />
                    </div>
                    <div className="footer-links-grid">
                        <div className="footer-items-links">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h5 className="text-light">{t("Company")}</h5>
                                    <p className="footer-links text-light" onClick={()=>{window.location.href="/#home"}}>{t("Home")}</p>
                                    <p className="footer-links text-light" onClick={()=>{window.location.href="/#services"}}>{t("Services")}</p>
                                    <p className="footer-links text-light" onClick={()=>{window.location.href="/#about"}}>{t("About us")}</p>
                                    <p className="footer-links text-light" onClick={()=>{window.location.href="/#contact"}}>{t("Contact us")}</p>
                                    <p className="footer-links text-light" onClick={()=>{window.location.href="/work-with-us"}}>{t("Work with us")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="footer-items-contact">
                            <h5 className="text-light text-center">{t("Contact Us")}</h5>
                            <p className="text-light-gray text-center">
                                Stockholm, Sweden
                            </p>
                            <p className="text-light-gray text-center">Phone: +46 760211685</p>
                            <p className="text-light-gray text-center">Email: info@thas-multitech.com</p>
                        </div>
                    </div>
                </div>

                <div className="mt-5 copyright-div">
                    <p className="text-white text-center m-0 copyright-text">
                        &copy; Copyright 2023 | THAS Multitech AB | All rights reserved.
                    </p>
                </div>

            </Box>

        </section>
    );
};

export default Footer;
