import React from 'react'
import img4 from "../assests/Group 10367.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const Advertisement = () => {

    return (
        <>
            <ServicesComponent
               img={img4}
               thead="Social Media Advertisement"
               desc="At THAS Multitech, we value the different aspects of social media advertising in the evolving digital world. Our approach is to work closely with you, understanding your needs and preferences. We not only help in crafting graphically designed posts and editing video clips for advertising but also manage your social media presence. With respect to your wishes, we can publish these posts as often as desired. Our aim is to be a supportive partner in your social media journey, assisting you step by step."
               idName="advertisement"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default Advertisement