import React, { useState } from 'react';
import './ContactUs.css';
import img from "../../assests/Layer 0.png";
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ContactUs = ({ border }) => {
    const { t, i18ns } = useTranslation();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (name.trim() === "" || email.trim() === "" || message.trim() === "") {
            toast("Please fill in all fields.", {
                closeButton: false,
                closeOnClick: false,
            });
        } else {
            setIsLoading(true);
            try {
                console.log("acce");
                const resp = await axios
                    .post(`https://thas-mail-service.com/send-email/`,
                        {

                            "subject": `New Message from ${name} at THAS Multitech AB.`,
                            "name": name,
                            "id": "9fa8bdf3-badb-4a8b-9a88-efb7eab0aa13",
                            "message": message,
                            "reply_to": email

                        },
                        {
                            headers: {
                                "Content-type": "application/json",
                                Authorization: "Token JKDAQY6R7c0MjqfMO9fwW9YttCQpupbGwq7RCz9U0QjajjXXlvGGTsskC9sIQzEf",
                            },
                        })
                    .then((response) => {
                        toast("Email sent successfully. ", {
                            closeButton: false,
                            closeOnClick: false,
                        });
                        setIsLoading(false);
                        setEmail("")
                        setName("")
                        setMessage("")
                        return response;
                    });
            } catch (error) {
                console.log(error);
                toast("something went wrong. ", {
                    closeButton: false,
                    closeOnClick: false,
                });
                setIsLoading(false);
            }
        }
    }

    return (
        <div>


            <div className={`contact-container ${border ? 'top-border-radius' : ''}`}>
                <div className="contact-text">
                    <h2>{t('title')}</h2>
                    <p>{t('description')}</p>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <input value={name} type="text" placeholder={t('namePlaceholder')} onChange={(e) => setName(e.target.value)} />
                        <input value={email} type="email" placeholder={t('emailPlaceholder')} onChange={(e) => setEmail(e.target.value)} />
                        <textarea value={message} placeholder={t('messagePlaceholder')} onChange={(e) => setMessage(e.target.value)}></textarea>
                        <button className='btn btn-secondary' type='submit'>
                            {isLoading ? 'Sending..' : t('Send Message')}
                        </button>
                    </form>

                </div>

                <div className="contact-image">
                    <img src={img} alt="Thas Multitech AB" width="auto"
                        height="auto"/>
                </div>

            </div>


        </div>
    );
}

export default ContactUs;
