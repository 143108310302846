import React, { useState, useEffect } from 'react'
import img1 from "../assests/Group 103.png";
import img2 from "../assests/Group 104.png";
import img3 from "../assests/Group 1037.png";
import img4 from "../assests/Group 10367.png";
import img5 from "../assests/Group 10370.png";
import img6 from "../assests/Group 10371.png";
import img7 from "../assests/Group 10372.png";
import img8 from "../assests/Group 1036.png";
import Hero from '../components/Hero/hero'
import Services from '../components/services/Services'
import WebDevelopment from '../components/work/WebDevelopment'
import ContactUs from '../components/contactUs/ContactUs'
import Designs from '../components/Designs/Designs'
import AboutUs from '../components/About/About'
import About1 from '../components/About1/About1'
import AppDevelopment from '../components/work/AppDevelopment'

const Home = () => {
    const [screenSize, setScreenSize] = useState('')
    const [showScrollToTop, setShowScrollToTop] = useState(false);


    useEffect(() => {
        const checkScrollTop = () => {
            if (!showScrollToTop && window.pageYOffset > 400) {
                setShowScrollToTop(true);
            } else if (showScrollToTop && window.pageYOffset <= 400) {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', checkScrollTop);

    }, [showScrollToTop]);


    useEffect(() => {
        // Function to update the screen size state

        const handleResize = () => {
            if (window.matchMedia('(max-width: 768px)').matches) {
                setScreenSize('small');
            } else if (window.matchMedia('(min-width: 1200px)').matches) {
                setScreenSize('large');
            } else {
                setScreenSize('medium');
            }
        };

        // Initial call to set the initial screen size
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            <main>
                <div >
                    <div id='home'>
                        <Hero />
                    </div>
                

                    <div id='services'>
                        <Services />
                    </div>
                    

                 <div id='web-development'>
                        <WebDevelopment />
                    </div>
                  

                    <div id='app-development'>
                        <AppDevelopment />
                    </div> 
                    

                    <div id='about'>
                        <AboutUs />
                        <About1 />
                    </div>

                    <div id='contact'>
                        <div id='contact-scroll'>
                            <ContactUs border={true} />

                        </div>
                    </div>
                   
                   
                    {showScrollToTop &&
                        <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                            style={{
                                position: 'fixed',
                                right: '20px',
                                bottom: '20px',
                                height: '50px',
                                width: '50px',
                                fontSize: '30px',
                                borderRadius: '50%', // Makes the button round
                                backgroundColor: '#BB68C8', // A distinct purple color
                                color: 'white', // White arrow
                                border: 'none', // Removes the border
                                boxShadow: '0 2px 5px rgba(0,0,0,0.3)', // Adds a slight shadow for depth
                                cursor: 'pointer', // Changes the cursor to signify it's clickable
                                zIndex: 1000, // Ensure it's above other elements
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            &uarr;
                        </button>
                    }
                </div>
            </main>
        </>
    )
}

export default Home