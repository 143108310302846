import React from 'react'
import img5 from "../assests/Group 10370.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const CadDesign = () => {

    return (
        <>
            <ServicesComponent
                img={img5}
                thead="CAD Design"
                desc="At THAS Multitech, we specialize in creating precise CAD designs focused on mechanical elements and objects. Our team is adept at building detailed 3D models of devices and various objects, ensuring your concepts are visualized accurately."
                idName="cad-design"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default CadDesign