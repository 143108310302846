import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../assests/thas multi tech@2x.png';
import flagEn from '../../assests/English.png';
import flagAr from '../../assests/sweden.png'; // Arabic flag
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import i18n from '../../assests/i18n/i18n';

const Navbar = () => {
    const { t, i18ns } = useTranslation();
    const nav = useNavigate();
    const pathname = window.location.hash;
    const lan = localStorage.getItem('lang') || 'swe'
    const [currentLanguage, setCurrentLanguage] = useState(lan);
    let newTranslationFunction = t;

    console.log(lan)
    useEffect(()=>{
        i18n.changeLanguage(lan)
        setCurrentLanguage(lan)
        newTranslationFunction = i18n.getFixedT(lan);
    }, [])
    const [dropdownOpen, setDropdownOpen] = useState(false);


    const languages = {
        swe: {
            flag: flagAr,
            label: 'Swedish'
        },
        en: {
            flag: flagEn,
            label: 'English'
        }

    };
    const handleLanguageChange = (lang) => {
        // Implement logic to change the language using i18next or your chosen method
        setDropdownOpen(false);
        setCurrentLanguage(lang);
        if (lang === "en") {
            i18n.changeLanguage('en')
            localStorage.removeItem('lang')
            localStorage.setItem('lang', 'en')
            newTranslationFunction = i18n.getFixedT(lan);
        } else {
            i18n.changeLanguage('swe')
            localStorage.removeItem('lang')
            localStorage.setItem('lang', 'swe')
            newTranslationFunction = i18n.getFixedT(lan);

        }
    };

    const navbarCollapseRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (navbarCollapseRef.current && !navbarCollapseRef.current.contains(event.target)) {
            const isNavbarExpanded = navbarCollapseRef.current.querySelector('.navbar-collapse.show');
            if (isNavbarExpanded) {
                const navbarToggle = document.querySelector('.navbar-toggler');
                if (navbarToggle) {
                    navbarToggle.click(); // Trigger the collapse
                }
            }
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [navbarCollapseRef]);

    return (
        <nav className="navbar navbar-expand-xl shadow-sm py-2" ref={navbarCollapseRef}  style={{ backgroundColor: '#FFFFFF' }}>
            <div className="container">
                <div className='navbar-brand' style={{ fontSize: "1rem", color: '#5C5C5C', cursor:"pointer" }} onClick={() => nav('/')}>
                    <img src={logo} alt="Thas Multitech AB" width={150} height={80} /> <br />
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">

                        <li className="nav-item">
                            <a className={`nav-link ${pathname.includes('#home') ? "active" : ""}`} href="/#home">{newTranslationFunction('Home')}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${pathname.includes('#services') ? "active" : ""}`} href="/#services">{newTranslationFunction('Services')}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${pathname.includes('#about') ? "active" : ""}`} href="/#about">{newTranslationFunction("About us")}</a>
                        </li>


                        <li className="nav-item">
                            <a className="nav-link" href="/#contact" style={{ backgroundColor: '#BB68C8', borderRadius: '10px', padding: '10px 20px', color: '#FFFFFF', fontWeight: 'normal' }}>
                                {newTranslationFunction("Get in Touch")}
                            </a>
                        </li>

                       {currentLanguage? <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={languages[currentLanguage].flag} alt={languages[currentLanguage].label} width={20} height={"auto"} />
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {Object.keys(languages).map(lang => (
                                    <li key={lang} onClick={() => handleLanguageChange(lang)}>
                                        <a className="dropdown-item" href="#">
                                            <img src={languages[lang].flag} alt={languages[lang].label} width={20} height={"auto"} className="me-2" />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>:null}

                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
