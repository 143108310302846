import React from 'react'
import img8 from "../assests/Group 1036.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const Animation = () => {

    return (
        <>
            <ServicesComponent
                img={img8}
                thead="Animated Video Production"
                desc="Elevate your marketing with our animated video production at THAS Multitech. Our expertise in 2D, 3D, and motion graphics animation enables us to create compelling videos that capture the essence of your apps and business, engaging your audience with creativity."
                idName="animated-video"
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default Animation