import React from 'react'
import ContactUs from '../components/contactUs/ContactUs';
import WebDevelopment from '../components/work/WebDevelopment';

const WebDevelopmentPage = () => {

    return (
        <>
            <WebDevelopment showOnNew={true} />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default WebDevelopmentPage