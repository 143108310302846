import React, { useState, useEffect } from "react";
import { get, ref } from "@firebase/database";
import { db } from "../../firebaseConfig";
import "./Work.css";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { Helmet } from 'react-helmet'; 
const AppDevelopment = () => {
  const total_d = 4
  const { t, i18ns } = useTranslation();
  const [appDevData, setappDevData] = useState([]);
  const [AllAppDevData, setAllAppDevData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isShowViewMore, setisShowViewMore] = useState(true);
  const appDevRef = ref(db, "app-templates");
  const [isLoading, setIsLaoding] = useState(false);
  const nav = useNavigate();
  useEffect(() => {
    setIsLaoding(true);
    get(appDevRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const result = [];

          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            const data = childSnapshot.val();
            const resultItem = { key, data };
            result.push(resultItem);
          });
          setIsLaoding(false);
          setappDevData(result.slice(0, total_d));
          setAllAppDevData(result);
        } else {
          setIsLaoding(false);
          console.log("Template do not exist.");
        }
      })
      .catch((error) => {
        console.error("Error fetching Treatment Texts:", error);
      });
  }, [isDataLoaded]);

  const handleViewMore = () => {
    const resp = AllAppDevData;
    setappDevData(resp);
    setisShowViewMore(false);
  };

  const handleViewLess = () => {
    const resp = AllAppDevData.slice(0, total_d);
    setappDevData(resp);
    setisShowViewMore(true);
  };

  return (
    <section className="work-section">
         <Helmet>
          {/* <title>App Development - Our Featured Work</title> */}
          <meta name="description" content="Explore our collection of innovative app development projects, showcasing our expertise in creating user-centric mobile applications." />
      </Helmet>
      <div
        style={{
          background: "#fff",
          backgroundSize: "100% 50%",
          opacity: "1",
        }}
      >
        <Box className="work-container">
          <div style={{ padding: "2rem 0" }}>
            <div className="d-flex justify-content-center mb-3">
              <Typography
                variant="h4"
                component={"h4"}
                className="m-0 mb-3 text-heading"
              >
                {t("App Development")}
              </Typography>
            </div>

            {isLoading ? (
               <Skeleton style={{height: "50vh"}}></Skeleton>
            ) : (
              <>
                <div className="template-thumbnails-grid text-center">
                  {appDevData.map((data, index) => (
                    <div key={index}>
                      <img
                      width="100"
                      height="100"
                        src={data.data["cover"]}
                        className="img-thumbnail app-template-thumbnail-items mb-3 mt-4"
                        alt="App Development Thumbnail"
                        onClick={() => {
                          nav(`/app-template/${data.key}`);
                        }}
                      />
                      <span className="thas-subheading template-name text-gray">
                        {data.data["title"]}
                      </span>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-center work-view-more-section">
                  {isShowViewMore ? (
                    <button
                      className="btn btn-secondary"
                      style={{ width: "15%" }}
                      onClick={() => handleViewMore()}
                    >
                      View More
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      style={{ width: "15%" }}
                      onClick={() => handleViewLess()}
                    >
                      View Less
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Box>
      </div>
    </section>
  );
};

export default AppDevelopment;
