import React from 'react'
import img3 from "../assests/Group 1037.png";
import ServicesComponent from '../components/ServicesComponents/ServicesComponent';
import ContactUs from '../components/contactUs/ContactUs';

const EmbeddedProgramming = () => {

    return (
        <>
            <ServicesComponent
                img={img3}
                thead="Embedded Programming"
                desc="At THAS Multitech, we have experience in the field of embedded programming. We continuously strive to learn and grow, and our team works on developing solutions for embedded systems. Whether you have an existing system in need of improvements or are considering starting a new project, we are here to assist and support you throughout the process."
                idName="embedded-programming"
                height={"452px"}
                top={"-3px"}
            />
            <div id='contact'>
                <div id='contact-scroll'>
                    <ContactUs border={true} />

                </div>
            </div>

        </>


    )
}

export default EmbeddedProgramming